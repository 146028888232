import Fashionimage1 from '../../asserts/Fashion.webp'
import Fashionimage2 from '../../asserts/Fashion2.webp'
import Fashionimage3 from '../../asserts/Fashion3.webp'
import Fashionimage4 from '../../asserts/Fashion4.webp'

import StockPilotimage1 from '../../asserts/StockPilot.webp'

import MyBoundariesimage1 from '../../asserts/myboundaries.webp'

import keenulabsimage1 from '../../asserts/keenulabs.webp'
import keenulabsimage2 from '../../asserts/keenulabs2.webp'
import keenulabsimage3 from '../../asserts/keenulabs3.webp'

import startupassistantimage1 from '../../asserts/startup-assistant.webp'
import startupassistantimage2 from '../../asserts/startup-assistant2.webp'
import startupassistantimage3 from '../../asserts/startup-assistant3.webp'

import flyfareimage1 from '../../asserts/flyfare.webp'
import flyfareimage2 from '../../asserts/flyfare2.webp'
import flyfareimage3 from '../../asserts/flyfare3.webp'

import betbullsimage1 from '../../asserts/betbulls.webp'
import betbullsimage2 from '../../asserts/betbulls2.webp'
import betbullsimage3 from '../../asserts/betbulls3.webp'

import devnestimage1 from '../../asserts/devnest.webp'
import devnestimage2 from '../../asserts/devnest2.webp'
import devnestimage3 from '../../asserts/devnest3.webp'
import devnestimage4 from '../../asserts/devnest4.webp'

import sereneimage1 from '../../asserts/serene.webp'
import sereneimage2 from '../../asserts/serene2.webp'
import sereneimage3 from '../../asserts/serene30.webp'

import estateEliteimage1 from '../../asserts/estateElite.webp'
import estateEliteimage2 from '../../asserts/estateElite2.webp'
import estateEliteimage3 from '../../asserts/estateElite3.webp'

import gardengearsimage1 from '../../asserts/gardengears.webp'
import gardengearsimage2 from '../../asserts/gardengears2.webp'
import gardengearsimage3 from '../../asserts/gardengears3.webp'

import sepeaimage1 from '../../asserts/sepea.webp'
import sepeaimage2 from '../../asserts/sepea2.webp'

import vitasynimage1 from '../../asserts/vitasyn.webp'
import vitasynimage2 from '../../asserts/vitasyn2.webp'
import vitasynimage3 from '../../asserts/vitasyn3.webp'

import valuewiseimage1 from '../../asserts/valuewise.webp'
import valuewiseimage2 from '../../asserts/valuewise2.webp'

import mindfullyimage1 from '../../asserts/mindfully.webp'

import wellnessimage1 from '../../asserts/wellness.webp'
import wellnessimage2 from '../../asserts/wellness2.webp'
import wellnessimage3 from '../../asserts/wellness3.webp'

import redbowieimage1 from '../../asserts/redbowie.webp'
import redbowieimage2 from '../../asserts/redbowie2.webp'
import redbowieimage3 from '../../asserts/redbowie3.webp'

import NSGimage1 from '../../asserts/NSG.webp'
import NSGimage2 from '../../asserts/NSG2.webp'
import NSGimage3 from '../../asserts/NSG3.webp'
import HtmlZeffo from '../../asserts/zeffo.webp'
import HtmlZeffo1 from '../../asserts/zeffo2.webp'


export const products = [
    {
        id:'ReactFashion',
        image:
        [
            Fashionimage1,
            Fashionimage2,
            Fashionimage3,
            Fashionimage4,
        ],
        name: 'Fashion', 
        author: 'EgeneusCo',
        shortdescription: 'AI-powered app: Upload an image to preview new styles and get personalized outfit suggestions',
        description: 'Ai Generated Application through which you can generate a better style experience by providing an image. This application show the exact result of new styling experience and also detect the products which yu will wear in your picture and also make latest result for that and give a suugestion below to make a real experience.',
        type: 'React',
        link:'https://fashion-co.vercel.app/',
        price: 200
    },
    {
        id:'HtmlZeffo',
        image:[
            HtmlZeffo,
            HtmlZeffo1,
        ],
        name: 'Zeffo', 
        author: 'EgeneusCo',
        shortdescription: 'Zeffo Parking Services world of easy parking. Get in touch with us right now to improve your parking experience.',
        description: 'As the best option for parking solutions, Zeffo Parking Services stands apart. Modern facilities that are tailored to meet a variety of parking demands demonstrate our commitment to excellence. We provide unparalleled convenience, effectiveness, and safety with a focus on client pleasure.',
        type: 'Html',
        link:'https://husnain1243.github.io/Zeffo/',
        price: 60 
    },
    {
        id:'LaravelSerene',
        image:
        [
            sereneimage1,
            sereneimage2,
            sereneimage3,
        ],
        name: 'Serene', 
        author: 'EgeneusCo',
        shortdescription: 'Serene Cosmetics specializes in crafting high-quality products that reflect your brands essence.',
        description: 'We also offer private labeling products that are tailor-made according to your brand’s requirements. Apart from being a private labeling manufacturer, we are also a manufacturer of cosmetic products with Herbal formulas. Let’s onboard your brand with serene!',
        type: 'Laravel',
        link:'https://husnainthepro.info/serene/home',
        price: 150
    },
    {
        id:'LaravelFlyFare',
        image:
        [
            flyfareimage1,
            flyfareimage2,
            flyfareimage3,
        ],
        name: 'FlyFare', 
        author: 'EgeneusCo',
        shortdescription: 'Travel around the world get cheapest and comfortable flights only with flyfare.',
        description: 'Explore the world affordably and comfortably with FlyFare, your go-to for finding the cheapest flights. Discover seamless travel experiences with our user-friendly platform, ensuring you reach your destination stress-free and within budget.',
        type: 'Laravel',
        link:'https://husnainthepro.info/fly-fare/home',
        price: 200
    },
    {
        id:'LaravelMyBoundaries',
        image:
        [
            MyBoundariesimage1,
        ],
        name: 'MyBoundaries', 
        author: 'EgeneusCo',
        shortdescription: 'Simplifies your digital experience. Define your limits and thrive in online and offline worlds',
        description: 'App helps to eliminate your digital distractions and lets you to reconnect with real world. Gain healthy and productive lifestyle by disconnecting yourself with unwanted contacts. Use the most advanced features to block calls and messages at any time for any specific slot you want. ',
        type: 'Laravel',
        link:'https://husnainthepro.info/boundaries/home',
        price: 160
    },
    {
        id:'LaravelKeenulabs',
        image:
        [
            keenulabsimage1,
            keenulabsimage2,
            keenulabsimage3,
        ],
        name: 'Keenulabs', 
        author: 'EgeneusCo',
        shortdescription: 'Embark On A Limitless Journey Of Possibilities With Keenu Labs, Your Trailblazing SAAS Product Company',
        description: 'Your Innovation Partner In The Digital Frontier! Embark On A Journey Of Limitless Possibilities With Keenu Labs, A Trailblazing SAAS Product Company. We are Not Just About Solutions; We are About Redefining What is Possible. Keenu Labs - Where Ideas Ignite!',
        type: 'Laravel',
        link:'https://husnainthepro.info/keenulabs/home',
        price: 120
    },
    {
        id:'LaravelStartupAssistant',
        image:
        [
            startupassistantimage1,
            startupassistantimage2,
            startupassistantimage3,
        ],
        name: 'StartupAssistant', 
        author: 'EgeneusCo',
        shortdescription: 'Unlock the potential of your startup with our AI-driven business communication solutions.',
        description: 'Welcome to the hub of startup assistance, where small business resources meet cutting-edge technology. Our platform and app are designed to support your business launch with virtual assistant services, AI customer support, and integrated chatbots tailored for startups.',
        type: 'Laravel',
        link:'https://husnainthepro.info/startupassistant/home',
        price: 220
    },
    {
        id:'LaravelBetbulls',
        image:
        [
            betbullsimage1,
            betbullsimage2,
            betbullsimage3,
        ],
        name: 'Betbulls', 
        author: 'EgeneusCo',
        shortdescription: 'Investing in the future of innovative sports entertainment, shaping the next generation of experiences.',
        description: 'Investing in the future of sports entertainment, we are committed to pioneering innovative experiences that redefine the industry. Our focus is on shaping the next generation of engaging and immersive sporting events, leveraging cutting-edge technology and creative talent. Join us in pushing boundaries and delivering unforgettable moments that captivate audiences worldwide, setting new standards in sports entertainment.',
        type: 'Laravel',
        link:'https://husnainthepro.info/betbulls/home',
        price: 150
    },
    {
        id:'LaravelDevnest',
        image:
        [
            devnestimage1,
            devnestimage2,
            devnestimage3,
            devnestimage4,
        ],
        name: 'Devnest', 
        author: 'EgeneusCo',
        shortdescription: 'Empowering innovation to enable excellence in every endeavor, driving transformative change.',
        description: 'Step into our digital realm where innovation meets expertise! Explore cutting-edge Web Development, dynamic App Development, powerful Big Data solutions, and bespoke Custom Development. Elevate your digital journey with us.',
        type: 'Laravel',
        link:'https://husnainthepro.info/devnest/home',
        price: 150
    },
    {
        id:'LaravelEstateElite',
        image:
        [
            estateEliteimage1,
            estateEliteimage2,
            estateEliteimage3,
        ],
        name: 'EstateElite', 
        author: 'EgeneusCo',
        shortdescription: 'We are a real estate agency that will help you find the best residence you dream of, let’s discuss',
        description: 'EstateElite is the result of a common ambition driven by three strong personalities with complementary profiles. A story that has lasted since 2014, around three experienced professionals and their team, united around common human values.',
        type: 'Laravel',
        link:'https://husnainthepro.info/estate-elite/know-us',
        price: 120
    },
    {
        id:'LaravelGardenGears',
        image:
        [
            gardengearsimage1,
            gardengearsimage2,
            gardengearsimage3,
        ], 
        name: 'GardenGears', 
        author: 'EgeneusCo',
        shortdescription: 'Your trusted source for reliable power solutions engines to robust generators for home and outdoor use.',
        description: 'Count on Gardengear as your trusted provider of reliable power solutions. We specialize in gasoline engines and robust generators designed for both home and outdoor environments. Whether you need dependable backup power or reliable performance in remote locations, Gardengear delivers products that ensure peace of mind and functionality wherever you are.',
        type: 'Laravel',
        link:'https://husnainthepro.info/garden-gears/home',
        price: 120
    },
    {
        id:'LaravelSapea',
        image:
        [
            sepeaimage1,
            sepeaimage2,
        ], 
        name: 'Sapea', 
        author: 'EgeneusCo',
        shortdescription: 'Started as regional manufacturer and produces industry leading solution in the areas of packing and tableware',
        description: 'Originally a regional manufacturer, we have evolved into an industry leader in packing and tableware solutions. Our commitment to innovation and quality drives us to produce cutting-edge products that meet the highest standards. From sustainable packaging solutions to stylish tableware designs, we cater to diverse needs with reliability and excellence.',
        type: 'Laravel',
        link:'https://husnainthepro.info/sapea/home',
        price: 100
    },
    {
        id:'LaravelVitasync',
        image:
        [
            vitasynimage1,
            vitasynimage2,
            vitasynimage3,
        ], 
        name: 'Vitasync', 
        author: 'EgeneusCo',
        shortdescription: 'Amid rising COVID-19 cases, prioritize your health with our expert consultations.',
        description: 'Amid rising COVID-19 cases, it is crucial to prioritize your health. Our expert consultations are here to provide personalized guidance and support, ensuring you stay informed and proactive in managing your well-being. Whether you have concerns about symptoms, preventive measures, or general health advice, our dedicated team is ready to assist you every step of the way. Trust in our comprehensive care to help you navigate these challenging times with confidence and peace of mind.',
        type: 'Laravel',
        link:'https://husnainthepro.info/vitasync/home',
        price: 160
    },
    {
        id:'LaravelValueWise',
        image:
        [
            valuewiseimage1,
            valuewiseimage2,
        ], 
        name: 'ValueWise', 
        author: 'EgeneusCo',
        shortdescription: 'Empower yourself with comprehensive financial solutions, tailored to help you achieve peace.',
        description: 'Empower yourself with our comprehensive financial and debt management solutions. We provide personalized strategies to help you achieve financial stability and reduce debt. Our expert advice and tools are designed to guide you through budgeting, savings, and debt repayment. Achieve peace of mind and a brighter financial future with our dedicated support.',
        type: 'Laravel',
        link:'https://husnainthepro.info/value-wise/home',
        price: 100
    },
    {
        id:'HtmlNSG',
        image:
        [
            NSGimage1,
            NSGimage2,
            NSGimage3,
        ], 
        name: 'NSG', 
        author: 'EgeneusCo',
        shortdescription: 'our neuropsychologists’ goal is to help our patients improve their cognitive functioning and mental health wellness.',
        description: 'The Clinicians at NSG partner with other health professionals to increase diagnostic accuracy and improve patient outcomes through empirically-based neuropsychological assessments. Our providers have experience with complex neurological presentations and are well-versed in discerning functional impairments from underlying psychiatric and neurological symptoms.',
        type: 'Html',
        link:'https://husnainthepro.info/nsg/home',
        price: 60 
    },
    {
        id:'HtmlStockPilot',
        image:
        [
            StockPilotimage1,
        ],
        name: 'StockAgency', 
        author: 'EgeneusCo',
        shortdescription: 'Streamline investments with our app, offering personalized insights and alternative opportunities.',
        description: 'Unlock diverse investment strategies with our user-friendly app. Gain personalized market insights and explore a range of alternative investment opportunities tailored to your financial goals. Simplify your investment decisions with intuitive tools and expert recommendations—all at your fingertips.',
        type: 'Html',
        link:'https://egenius1243.github.io/stock_pilot/',
        price: 400
    },
    {
        id:'HtmlMindMation',
        image:
        [
            mindfullyimage1,
        ],
        name: 'MindMation', 
        author: 'EgeneusCo',
        shortdescription: 'Personalized mental health support connecting you with compassionate counselors who prioritize your well-being.',
        description: 'Mindmation offers a compassionate approach to mental health care, providing personalized support through our team of dedicated counselors. Whether you are seeking guidance for anxiety, depression, or other challenges, our platform connects you with professionals who understand your needs. Take the first step towards better mental health with Mindmation, where your well-being is our priority.',
        type: 'Html',
        link:'https://mindfully.com/',
        price: 250
        
    },
    {
        id:'HtmlWellness',
        image:
        [
            wellnessimage1,
            wellnessimage2,
            wellnessimage3,
        ], 
        name: 'Wellness', 
        author: 'EgeneusCo',
        shortdescription: 'Indulge in personal wellness and meaningful connections at our center, fostering experience for mind and spirit.',
        description: 'Experience holistic wellness at our center, where we prioritize your physical and emotional well-being. Discover a range of therapies and activities designed to rejuvenate your body and mind. Our caring staff provides personalized attention and support, ensuring each visit is tailored to your needs. Whether you seek relaxation, fitness, or therapeutic treatments, find solace and connection in our welcoming environment. Elevate your well-being with us, where self-care meets community.',
        type: 'Html',
        link:'https://egenius1243.github.io/wellness_center/',
        price: 100 
    },
    {
        id:'HtmlFitness',
        image:
        [
            redbowieimage1,
            redbowieimage2,
            redbowieimage3,
        ], 
        name: 'Fitness', 
        author: 'EgeneusCo',
        shortdescription: 'Your ultimate fitness resource: expert advice, workouts, nutrition tips, and support for your wellness journey.',
        description: 'Discover a wealth of health and fitness knowledge at our site, offering expert advice on workouts, nutrition plans, and holistic wellness. Whether you are a beginner or seasoned athlete, find tailored resources to support every aspect of your fitness journey. Join a community committed to achieving optimal health with personalized guidance and motivational tips.',
        type: 'Html',
        link:'https://egenius1243.github.io/red_bowie/',
        price: 60 
    },
  ];
  
  
  
  
  
  
  