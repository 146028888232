import React, { useState } from 'react';

const ConsentBanner = () => {
  const [consentGiven, setConsentGiven] = useState(false);
  const [hideConsent, setConsenthide] = useState('d-block');
  function gtag() {
    window.dataLayer.push(arguments);
  }
  const handleConsent = () => {
    setConsentGiven(true);
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
    });
    setTimeout(() => {
        setConsenthide('d-none');
    }, 1000);
  };
  const handleDeny = () => {
    setConsentGiven(false);
    gtag('consent', 'update', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
    });
    setConsenthide('d-none');
  };

  return (
    <div className={`Consent-banner ${hideConsent}`}>
      {consentGiven ? (
        <h2 className='h2 text-center text-light'>Thank you for giving your consent.</h2>
      ) : (
        <div className='consent-data d-flex flex-wrap justify-content-around'>
          <h2 className='h3 text-center text-light'>We use cookies to personalize your experience.</h2>
          <div className="consent-button-container d-flex justify-content-center gap-3">
            <button className='btn form-button consent-button consent-button1' onClick={handleConsent}>Accept</button>
            <button className='btn form-button consent-button consent-button2' onClick={handleDeny}>Deny</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsentBanner;