import { BlogBanner } from "../component/BlogComponent/BlogBanner";
import { BlogCards } from "../component/Cards/BlogCards";
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const Blogs = () => {
  ScrollToTop();
  return (
    <div id="blogs-page" className="About-wrapper">
      <BlogBanner/>
      <div className="blogs-details py-4 py-lg-5">
        <BlogCards/>
      </div>  
    </div>
  );
};

export default withLayout(Blogs)