import React, { useState  , useEffect} from 'react';
import { AppRoutes } from "../routes/AppRoutes";
import ConsentBanner from "../pages/ConsentBanner";
import { useLocation } from 'react-router-dom';

export const AppLayout = () => {
  const location = useLocation();
  const [gtagInitialized, setGtagInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
 }, []);


  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  useEffect(() => {
    if (!gtagInitialized) {
      gtag('js', new Date());
      gtag('config', 'G-WZZFGWZ4TM', {
        'consent': 'pending',
        'consent_mode': 'v2',
      });
      setGtagInitialized(true);
    }
  }, [gtagInitialized]);
  return (
    <div className="app-container">
      {isLoading && location.pathname !== '/landingpage-v2' ? (
        <div className='loader-container'>
          <div className="spinner-box">
            <div className="blue-orbit leo"></div>
            <div className="green-orbit leo"></div>
            <div className="red-orbit leo"></div>
            <div className="white-orbit w1 leo"></div>
            <div className="white-orbit w2 leo"></div>
            <div className="white-orbit w3 leo"></div>
          </div>
        </div>
      ) : (
        location.pathname === '/login' || location.pathname === '/registration' || location.pathname === '/password-reset' ? (
          <AppRoutes/>
        ) : (
          <>
            <ConsentBanner/>
            <AppRoutes/>
          </>
        )
      )}
    </div>
  );
};
