import {FaRegEnvelope , FaGlobe , FaWhatsapp  } from 'react-icons/fa';
export const FooterWidget1 = () => {
    return(
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 mb-4">
            <div className="text-center text-md-start d-flex gap-2 flex-column">
                <h3>Reach Us At</h3>
                <ul className="list-unstyled d-flex flex-column gap-3 my-3"  style={{fontSize: "18px"}}>
                    <li>
                        <a href="https://wa.me/+923164604121" aria-label='phone'>
                            <FaWhatsapp style={{height:"20px", width:"20px"}}/>
                            <p className="d-inline mx-2">+92-3164604121</p>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:EgeneusCo@outlook.com" aria-label='mail'>
                            <FaRegEnvelope style={{height:"20px", width:"20px"}}/>
                            <p className="d-inline mx-2">EgeneusCo@outlook.com</p>
                        </a>
                    </li>
                    <li>
                        <a href="https://maps.app.goo.gl/WCjgbgGNHdT67DMz9" rel="noreferrer" aria-label='location' target="_blank">
                            <FaGlobe style={{height:"20px", width:"20px"}}/>
                            <p className="d-inline mx-2">Main Office</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}