import { ProductCards } from "../component/Cards/ProductCards";
import { Testimonials } from "../component/Testimonial/Testimonials"
import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import withLayout from '../component/withLayout/withLayout';

const Products = () => {
  ScrollToTop();
  return (
    <div id="products-page" className="product-wrapper">
      <div className="blog-banner d-flex flex-column justify-content-center bg-dark text-center">
        <div className="container">
          <div className="banner-container">
            <div className="col-12 col-md-7">
              <h1 className="text-start">Our Products</h1>
              <p className="p text-start">
                Discover EgeneusCo's diverse range of innovative digital products tailored to empower businesses. Explore our portfolio and find the perfect digital tools to elevate your business.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Product-details py-4 py-lg-5">
        <ProductCards/>
      </div>  
      <Testimonials/>
    </div>
  );
};

export default withLayout(Products)