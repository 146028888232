import imageHtml from '../../asserts/StockPilot.webp'
import imageHtml1 from '../../asserts/mindfully.webp'
import imageHtml2 from '../../asserts/wellness.webp'
import imageHtml3 from '../../asserts/armour.webp'
import imageHtml4 from '../../asserts/amazon.webp'
import imageHtml5 from '../../asserts/sinclair.webp'
import imageHtml6 from '../../asserts/redbowie.webp'
import imageHtml7 from '../../asserts/shash-landing-page.webp'
import imageHtml8 from '../../asserts/equip.webp'
import imageHtml9 from '../../asserts/love-cups.webp'
import imageHtml10 from '../../asserts/bertblanc.webp'
import imageWordpress1 from '../../asserts/krissaraspa.webp'
import imageWordpress2 from '../../asserts/goldenpainting.webp'
import imageWordpress5 from '../../asserts/devislawpractice.webp'
import imageWordpress6 from '../../asserts/EatsWithMish.webp'
import imageWordpress7 from '../../asserts/EcomDevelopment.webp'
import imageWordpress8 from '../../asserts/EolaPower.webp'
import imageWordpress9 from '../../asserts/FairWay.webp'
import imageWordpress10 from '../../asserts/Navidar.webp'
import imageWordpress11 from '../../asserts/NYC.webp'
import imageWordpress12 from '../../asserts/REVMKG.webp'
import imageWordpress13 from '../../asserts/UltraLuxeSkinCare.webp'
import imageWordpress14 from '../../asserts/360Spaces.webp'
import imageWordpress15 from '../../asserts/EyeCenter.webp'
import imageWordpress16 from '../../asserts/TopTrendingBlogs.webp'
import imageWordpress17 from '../../asserts/transducer.webp'
import imageWordpress18 from '../../asserts/unrevelgroups.webp'
import imageWordpress19 from '../../asserts/GreatAmericanSyndicate.webp'
import imageReact1 from '../../asserts/Fashion.webp'
import imageLaravel1 from '../../asserts/myboundaries.webp'
import imageLaravel2 from '../../asserts/keenulabs.webp'
import imageLaravel4 from '../../asserts/startup-assistant.webp'
import imageLaravel5 from '../../asserts/Followly.webp'
import imageLaravel6 from '../../asserts/doctorsforhealth.webp'
import imageLaravel7 from '../../asserts/brandsvalley.webp'
import imageLaravel8 from '../../asserts/dnation.webp'
import imageLaravel3 from '../../asserts/flyfare.webp'
import NSGimage1 from '../../asserts/NSG.webp'
import HtmlZeffo from '../../asserts/zeffo.webp'

export const products = [
    {
        id:'HtmlZeffo',
        image: HtmlZeffo,
        name: 'Zeffo', 
        author: 'EgeneusCo',
        description: 'One Pager site as discuss with the client.',
        type: 'Html',
        link:'https://husnain1243.github.io/Zeffo/',
        price: 60 
    },
    {
        id:'HtmlNSG',
        image: NSGimage1,
        name: 'NSG', 
        author: 'EgeneusCo',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://husnainthepro.info/nsg/home',
        price: 60 
    },
    {
        id:'Html0',
        image: imageHtml, 
        name: 'Stock Pilot', 
        author: 'Husnain',
        description: 'Complete Site as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/stock_pilot/',
        price: 600 
    },
    {
        id:'Html1',
        image: imageHtml1, 
        name: 'Mindfully', 
        author: 'Husnain',
        description: 'Complete Site as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/mindfully/',
        price: 450
        
    },
    {
        id:'Html2',
        image: imageHtml2, 
        name: 'Wellness Center', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/wellness_center/',
        price: 50 
    },
    {
        id:'Html3',
        image: imageHtml3, 
        name: 'armour', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/Armour/',
        price: 40 
    },
    {
        id:'Html4',
        image: imageHtml4, 
        name: 'Amazon', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/amazon_page/',
        price: 45 
        
    },
    {
        id:'Html5',
        image: imageHtml5, 
        name: 'Sinclair', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/Sinclair/',
        price: 50 
    },
    {
        id:'Html6',
        image: imageHtml6, 
        name: 'Red Bowie', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://egenius1243.github.io/red_bowie/',
        price: 60 
    },
    {
        id:'Html7',
        image: imageHtml7, 
        name: 'Shash', 
        author: 'Husnain',
        description: 'Product Detials Page discuss with client.',
        type: 'Html',
        link:'https://egenius1243.github.io/shash/',
        price: 40 
    },
    {
        id:'Html8',
        image: imageHtml8, 
        name: 'Equip', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://husnain1243.github.io/equip-live/',
        price: 30 
    },
    {
        id:'Html9',
        image: imageHtml9, 
        name: 'Love Cups', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://husnain1243.github.io/love-cupping/',
        price: 40 
    },
    {
        id:'Html10',
        image: imageHtml10, 
        name: 'Bertblanc', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'Html',
        link:'https://husnain1243.github.io/bertblanc/',
        price: 45
    },
    {
        id:'Wordpress1',
        image: imageWordpress1, 
        name: 'Krissaraspa', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://krissaraspa.com',
        price: 200
    },
    {
        id:'Wordpress2',
        image: imageWordpress2, 
        name: 'Golden Paining', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://goldenpaintingidaho.com/',
        price: 220
    },
    {
        id:'Wordpress3',
        image: imageHtml3, 
        name: 'Armour Carrier', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://armourcarrier.com/',
        price: 210
    },
    {
        id:'Wordpress4',
        image: imageHtml5, 
        name: 'Red Carpet', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://redcarpeteventsnyc.com/',
        price: 250
    },
    {
        id:'Wordpress5',
        image: imageWordpress5, 
        name: 'DavisLaw Practice', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://www.davislawpractice.com/',
        price: 290
    },
    {
        id:'Wordpress6',
        image: imageWordpress6, 
        name: 'EatWithMish', 
        author: 'Husnain',
        description: 'Wordpress Site Changings as discuss with the client.',
        type: 'Wordpress',
        link:'https://www.mishsen.com/',
        price: 150
    },
    {
        id:'Wordpress7',
        image: imageWordpress7, 
        name: 'EcomDevelopment', 
        author: 'Husnain',
        description: 'Wordpress Site Changings as discuss with the client.',
        type: 'Wordpress',
        link:'https://ecomdevelopment.us/',
        price: 140
    },
    {
        id:'Wordpress8',
        image: imageWordpress8, 
        name: 'EOLA Power', 
        author: 'Husnain',
        description: 'Wordpress Site Changings as discuss with the client.',
        type: 'Wordpress',
        link:'https://www.eolapower.com/',
        price: 80
    },
    {
        id:'Wordpress9',
        image: imageWordpress9, 
        name: 'FairWay', 
        author: 'Husnain',
        description: 'Wordpress Site Changings as discuss with the client.',
        type: 'Wordpress',
        link:'https://fairwaymortgagereverse.com/',
        price: 120
    },
    {
        id:'Wordpress10',
        image: imageWordpress10, 
        name: 'Navidar', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://www.navidar.com/',
        price: 240
    },
    {
        id:'Wordpress11',
        image: imageWordpress11, 
        name: 'NYC', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://nychomemd.com/',
        price: 290
    },
    {
        id:'Wordpress12',
        image: imageWordpress12, 
        name: 'REVMKG', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://revmkg.com/',
        price: 210
    },
    {
        id:'Wordpress13',
        image: imageWordpress13, 
        name: 'UltraLuxe Skin Care', 
        author: 'Husnain',
        description: 'Wordpress Site Changings as discuss with the client.',
        type: 'Wordpress',
        link:'https://ultraluxeskincare.com/',
        price: 100
    },
    {
        id:'Wordpress14',
        image: imageWordpress14, 
        name: '360 Spaces', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://the360spaces.com/',
        price: 210
    },
    {
        id:'Wordpress15',
        image: imageWordpress15, 
        name: 'The Eye Center', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://www.theeyecentersc.com/',
        price: 300
    },
    {
        id:'Wordpress16',
        image: imageWordpress16, 
        name: 'Top trending Blogs', 
        author: 'Husnain',
        description: 'Wordpress Site Changings as discuss with the client.',
        type: 'Wordpress',
        link:'http://www.toptrendingblogs.com/',
        price: 80
    },
    {
        id:'Wordpress17',
        image: imageWordpress17, 
        name: 'Transducers', 
        author: 'Husnain',
        description: 'Wordpress Site Some Changings as discuss with the client.',
        type: 'Wordpress',
        link:'https://transducersdirect.com/',
        price: 70
    },
    {
        id:'Wordpress18',
        image: imageWordpress18, 
        name: 'Unravel Groups', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://www.unravelgroups.com/',
        price: 250
    },
    {
        id:'Wordpress19',
        image: imageWordpress19, 
        name: 'Great American Syndicate', 
        author: 'Husnain',
        description: 'Wordpress Site Landing Page as discuss with the client.',
        type: 'Wordpress',
        link:'https://barballenspeaks.com/',
        price: 90
    },
    {
        id:'Wordpress20',
        image: imageHtml1, 
        name: 'Mindfully', 
        author: 'Husnain',
        description: 'Wordpress Full Site as discuss with the client.',
        type: 'Wordpress',
        link:'https://mindfully.com/',
        price: 320
    },
    {
        id:'React1',
        image: imageReact1, 
        name: 'Fashion', 
        author: 'Husnain',
        description: 'landing Page as discuss with the client.',
        type: 'React',
        link:'https://fashion-co.vercel.app/',
        price: 130
    },
    {
        id:'Laravel1',
        image: imageLaravel1, 
        name: 'My Boundaries', 
        author: 'Husnain',
        description: 'Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://husnainthepro.info/boundaries/home',
        price: 900
    },
    {
        id:'Laravel2',
        image: imageLaravel2, 
        name: 'Keenulabs', 
        author: 'Husnain',
        description: 'Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://husnainthepro.info/keenulabs/home',
        price: 1100
    },
    {
        id:'Laravel3',
        image: imageLaravel3, 
        name: 'Fly Fare', 
        author: 'Husnain',
        description: 'Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://husnainthepro.info/fly-fare/home',
        price: 200
    },
    {
        id:'Laravel4',
        image: imageLaravel4, 
        name: 'Startup Assistant', 
        author: 'Husnain',
        description: 'Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://husnainthepro.info/startupassistant/home',
        price: 1200
    },
    {
        id:'Laravel5',
        image: imageLaravel5, 
        name: 'Followly', 
        author: 'Husnain',
        description: 'Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://followly.me/',
        price: 2200
    },
    {
        id:'Laravel6',
        image: imageLaravel6, 
        name: 'Doctors For Health', 
        author: 'Husnain',
        description: 'A Part of Laravel Site as discuss with the client.',
        type: 'Laravel',
        link:'https://doctorsforhealth.co.uk/',
        price: 2400
    },
    {
        id:'Laravel7',
        image: imageLaravel7, 
        name: 'Brands Valley', 
        author: 'Husnain',
        description: 'A Part of Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://brandsvalley.net/',
        price: 2400
    },
    {
        id:'Laravel8',
        image: imageLaravel8, 
        name: 'Dnation', 
        author: 'Husnain',
        description: 'A Part of Laravel Full Site as discuss with the client.',
        type: 'Laravel',
        link:'https://dnation.co/',
        price: 2100
    },

  ];