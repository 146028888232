import { ProductContactForm } from "./ProductContactForm";
import { useLocation } from 'react-router-dom';
import withLayout from '../../component/withLayout/withLayout';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const ProductForm = () => {
    ScrollToTop();
    const location = useLocation();
    const productDetail = location.state.data;
    return(
        <div id="product_form" className="product_form_wrapper">
            <div className="py-4 py-lg-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 mb-4">
                            <div className="row align-items-center">
                                <div className="col-12 mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-5">
                                        <h1 className="text-dark mb-0">{productDetail.name}</h1>
                                        <h2 className="text-dark">${productDetail.price}</h2>
                                    </div>
                                    <div className="image-container mb-4">
                                        <div id="carouselExampleIndicators" className="carousel slide">
                                            <div className="carousel-inner">
                                            {
                                                productDetail.image.map((image, index) => (
                                                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                    <img src={image} alt={image} className="m-auto d-block img-fluid w-100 h-100" height="100%" width="100%" loading="lazy"/>
                                                </div>
                                                ))
                                            }
                                            </div>
                                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <h3 className="text-dark">{productDetail.shortdescription}</h3>
                                    <p className="text-dark">{productDetail.description}</p>
                                    <p className="text-dark"> 
                                        <a href={productDetail.link} rel="noreferrer" aria-label={productDetail.name} target="_blank" style={{ color: "#7BA447"}} className="ms-1">
                                            View Site
                                        </a>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-dark">Author: {productDetail.author}</p>
                                        <p className="text-dark">Type: {productDetail.type}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <ProductContactForm productData={productDetail}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withLayout(ProductForm)